export const publicMixin = {
    data() {
        return {
            dateValue:"",
            page:0,
            size:10
        };
    },
    methods: {
        reset() {
            this.queryData = {
                page:0,
                size:10
            }
            this.dateValue =''
            this.getData()
        },
            // 选择日期
            dateChange(date, dateString) {
                this.queryData.dateMin = dateString[0].toString();
                this.queryData.dateMax = dateString[1].toString();
                this.dateValue = date;
            },
    },
};

