<template>
 <div>
    <a-card style="background-color: #fff;" v-if="hasPermission('QUERY_BRAND')">
        <div :class="advanced ? 'search' : null">
            <a-form layout="horizontal">
                <div :class="advanced ? null : 'fold'">
                    <a-row>
<!--                        <a-col :md="8" :sm="24" style="max-width: 400px;">-->
<!--                            <a-form-item label="品牌状态" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">-->
<!--                                <a-select allowClear v-model="queryData.status" placeholder="请选择处理状态" option-label-prop="label">-->
<!--                                    <a-select-option value="1" label="正常">-->
<!--                                        正常-->
<!--                                    </a-select-option>-->
<!--                                    <a-select-option value="2" label="下架">-->
<!--                                        下架-->
<!--                                    </a-select-option>-->
<!--                                </a-select>-->
<!--                            </a-form-item>-->
<!--                        </a-col>-->
                        <a-col :md="8" :sm="24" style="max-width: 400px;">
                            <a-form-item label="品牌名称" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                                <a-input v-model="queryData.name" placeholder="品牌名称/关键词" />
                            </a-form-item>
                        </a-col>    
<!--                        <a-col :md="8" :sm="24" style="max-width: 400px;">-->
<!--                                <a-form-item label="渠道" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">-->
<!--                                    <a-select allowClear v-model="queryData.type" placeholder="请选择渠道"-->
<!--                                        option-label-prop="label">-->
<!--                                        <a-select-option value="1" label="自营">自营</a-select-option>-->
<!--                                        <a-select-option value="2" label="美欣">美欣</a-select-option>-->
<!--                                        <a-select-option value="3" label="多美味">多美味</a-select-option>-->
<!--                                    </a-select>-->
<!--                                </a-form-item>-->
<!--                            </a-col>-->
                        <a-col :md="8" :sm="24" style="max-width: 400px;">
                            <a-form-item label="创建时间" :labelCol="{ span: 5 }" :wrapperCol="{ span: 18, offset: 1 }">
                                <a-range-picker :value="dateValue" :ranges="{
                                    '今天': [moment(), moment()],
                                    '昨天': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
                                 '当月': [moment().startOf('month'), moment().endOf('month')],
  '上月': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
                                }" @change="dateChange" />
                            </a-form-item>
                        </a-col>
                        <a-col :md="8" :sm="24">
                            <a-button @click="reset" style="margin-left: 10px;">重置</a-button>
                            <a-button type="primary" style="margin-top: 4px;margin-left: 10px;"
                                @click="search">查询</a-button>
                                <a-button type="primary" style="margin-top: 4px;margin-left: 10px;"
                                @click="exportTask" v-if="hasPermission('EXPORT_BRAND')">导出</a-button>
                        </a-col>
                    </a-row>
                    <div style="margin-top: 10px;font-size: 15px;margin-left: 10px;">显示第 {{ total>0 ? 1 : 0 }} 至 {{ queryData.size > total ?  total : queryData.size }} 项结果，共 {{ total }} 项</div>

                </div>
            </a-form>

        </div>


        <div>
            
            <standard-table :columns="columns" :dataSource="dataSource" :loading="tableLoading" :pagination="false">
                <div slot="createTime" slot-scope="{text}">{{ formatDate(text) }}</div>
                <div slot="discount" slot-scope="{text}">{{ text * 100 }}</div>
                
                <div slot="status" slot-scope="{text}">
                    <span v-if="text == 1" style="color: #008000;">正常</span>
                    <span v-if="text == 2" style="color: #ff0000;">下架</span>
                </div>
                <template slot="pdtCat" slot-scope="text">
                        <a-popover>
                            <template slot="content">
                                {{ text.text }}
                            </template>
                            {{ text.text }}
                        </a-popover>
                    </template>
                
                <div slot="icon" slot-scope="{text}"><img style="width: 45px;" :src="text" alt=""></div>

                <!-- <div slot="action" slot-scope="{text}">
                    <a style="margin-right: 8px" @click="exportTask(text.id)">导出</a>
                </div> -->
            </standard-table>
            <div class="page-box">
                <a-pagination :total="total" :current="queryData.page + 1" :page-size-options="pageSizeOptions" :pageSize="queryData.size" @change="handlePageChange"  @showSizeChange="onShowSizeChange"   show-size-changer />
            </div>


        </div>

        <div style="text-align: center;font-size: 20px;margin-top: 100px;" v-if="!hasPermission('EXPORT_TASK_LIST')">
            <a-empty :description="false" />
            无权限查看
        </div>
    </a-card>

    <div style="text-align: center;font-size: 20px;margin-top: 100px;" v-else>
            <a-empty :description="false" />
            无权限查看
        </div>
 </div>
</template>
  
<script>
import StandardTable from '@/components/table/StandardTable'
import moment from 'moment';
import 'moment/locale/zh-cn';
import { request } from '@/utils/request'
import {publicMixin} from '@/mixin/public'
const columns = [
{ title: '序号', customRender: (text, record, index) => index + 1, align: 'center' },
    {
        title: '品牌名称',
        dataIndex: 'name',
        align: 'center'

    },
    {
        title: '图片',
        dataIndex: 'icon',
        scopedSlots: { customRender: 'icon' },
        align: 'center'
    },
    {
        title: '渠道',
        dataIndex: 'type',
        align: 'center'
    },
    // {
    //     title: '类目/结算类型',
    //     dataIndex: 'pdtCat',
    //     ellipsis: true,
    //     scopedSlots: { customRender: 'pdtCat' },
    //     width: 300,
    //     align: 'center'
    // },
    // {
    //     title: '微信买单优惠折扣%',
    //     dataIndex: 'discount',
    //     scopedSlots: { customRender: 'discount' },
    //     align: 'center'
    // },
    {
        title: '创建时间',
        dataIndex: 'createTime',
        scopedSlots: { customRender: 'createTime' },
        align: 'center'
    },
    {
        title: '状态',
        dataIndex: 'status',
        scopedSlots: { customRender: 'status' },
        align: 'center'
    },
    // {
    //     title: '操作',
    //     scopedSlots: { customRender: 'action' },
    //     align: 'center'
    // }
]

export default {
    name: 'QueryList',
    components: { StandardTable },
    mixin:[publicMixin],
    data() {
        return {
            moment,
            pageSizeOptions: ['10', '30', '40', '50', '100', '500','1000'],
            customerData: [],
            userData: [],
            advanced: true,
            columns: columns,
            dataSource: [],
            selectedRows: [],
                current: 1,
                pageSize: 10,
                total: 0,
            // 筛选
            queryData: {
                page: 0,
                size: 10,
              status:1,
                name: ''
            },

            tableLoading: false,
            downLoading: false,
            tabCurrent: 1,
            downCurrent: 1,
            downTotal: 0,
            tabTotal: 0,
            currentPage:1,
            dateValue:''

        }
    },
    authorize: {
        deleteRecord: 'delete'
    },
    mounted() {
        this.getData()

    },
    computed: {
        userPermissions() {
            return this.$store.state.account.user.authorityList;
        }
    },
    methods: {
        search(){
            this.queryData.page = 0;
            this.getData();
        },
        reset() {
            this.queryData = {
                page:0,
              status:1,
                size:10
            }
            this.dateValue =''
            this.getData()
        },
        onShowSizeChange(current, pageSize) {
            this.queryData.size = pageSize;
            this.getData()
        },
        handlePageChange(page) {
        this.queryData.page = page - 1;
        this.currentPage = page;
        this.getData()
      },
        hasPermission(permission) {
            return this.userPermissions.includes(permission);
        },
        // 选择日期
        dateChange(date, dateString) {
            this.queryData.dateMin = dateString[0].toString();
            this.queryData.dateMax = dateString[1].toString();
            this.dateValue=date;
        },
        // 获取列表 
        getData() {
            this.tableLoading = true;
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/scm/brand/list', 'post', this.queryData).then(res => {
                this.tableLoading = false;
                let data = res.data.data;
                if (res.data.code == 200) {
                    this.dataSource = data.data;
                    this.queryData.page = data.page;
                    this.queryData.size = data.size;
                    this.total = data.total;
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },
        tabPageChange(page) {
            this.queryData.page = page - 1;
            this.tabCurrent = page;
            this.getData()
        },
        exportTask() {
            this.$message.loading('处理中')
            let params = this.queryData;
            params.remarks = ''
            request(process.env.VUE_APP_API_BASE_URL + 'sfa/scm/brand/exportBrand', 'post', params).then(res => {
                if (res.data.code == 200) {
                    this.$message.success('导出成功！')
                } else {
                    this.$message.error(res.data.message)
                }
            })
        },

        // 格式化年月日
        formatDate(timestamp) {
            const date = new Date(timestamp);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            // const seconds = date.getSeconds().toString().padStart(2, '0');
            let result = year + '-' + month + '-' + day + ' ' + hours + ':' + minutes;
            return result
        },

        deleteRecord(key) {
            this.dataSource = this.dataSource.filter(item => item.key !== key)
            this.selectedRows = this.selectedRows.filter(item => item.key !== key)
        },
        toggleAdvanced() {
            this.advanced = !this.advanced
        },

    }
}
</script>
  
<style lang="less" scoped>
.search {
    // margin-bottom: 54px;
}

.fold {
    width: calc(100% - 216px);
    display: inline-block
}

.operator {
    margin-bottom: 18px;
}

@media screen and (max-width: 900px) {
    .fold {
        width: 100%;
    }
}

::v-deep .page-content {
    padding: 0 !important;
}

.cre-btn {
    width: 100%;
    height: 64px;
    line-height: 64px;
    background: #EEECFE;
    border-radius: 4px;
    border: 1px dashed #5542F6;
    cursor: pointer;
    font-size: 16px;
    font-family: PingFangHK-Regular, PingFangHK;
    font-weight: 400;
    color: #5542F6;
}

.btn-box button {
    width: 106px;
    height: 36px;
    margin-left: 10px;
}


.page-box {
    display: flex;
    justify-content: right;
    padding-right: 46px;
    margin-top: 10px;
}
// .ant-col-md-8 {
//     height: 40px !important;
// }
</style>
  